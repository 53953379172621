<template>
    <div class="card">
        <Processing v-if="isProcessing" />
        <div class="listview listview--bordered" v-else-if="news.length > 0">
            <div class="listview__item" v-for="(post, i) in news" :key="i">
                <div class="listview__image">
                    <img :src="post.photo == '' ? `img/placeholder.jpg` : post.photo" alt=".">
                </div>
                <div class="listview__content">
                    <div class="listview__heading">{{ post.title }}</div>
                    <div class="listview__ellipse">{{ post.content }}</div>
                    <div class="listview__attrs">
                        <span><em>Category:</em> {{ post.category }}</span>
                        <span><em>Author:</em> {{ post.author.email }}</span>
                        <span><em>Date:</em> {{ post.createdAt }}</span>
                    </div>
                </div>
                <div class="actions listview__actions">
                    <i class="actions__item zmdi zmdi-edit" @click="edit(post.id)"></i>
                    <i class="actions__item zmdi zmdi-eye" @click="view(post.id)"></i>
                    <i class="actions__item zmdi zmdi-delete" @click="remove(post.id, i)"></i>
                </div>
            </div>
        </div>
        <div v-else class="notfound">
            <img src="img/notfound.svg" alt="">
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            news: [],
            year: this.$store.state.devotionalYear,
            isProcessing: false,
        }
    },
    created(){
        this.load()
    },
    methods: {
        async load(){
            this.isProcessing = true
            const logs = []
            const snapshot = await this.$db.collection("news").orderBy("createdAt", "desc").get()
            snapshot.forEach(doc => {
                const data = doc.data()
                if (data.createdAt) {
                    data.createdAt = this.$moment(data.createdAt.toDate()).format("DD/MM/YYYY HH:mm:ss a")
                }
                logs.push({ id: doc.id, ...data })
            })
            this.news = logs
            // console.log(logs);
            this.isProcessing = false
        },
        view(id){
            this.$router.push("/news/n/" + id)
        },
        edit(id){
            this.$router.push("/news/n/" + id + "/edit")
        },
        remove(id, i){
            console.log(id, i);
        },
    },
    components: {
        "Processing": () => import("@/components/Loaders/Processing.vue"),
    }
}
</script>

<style scoped>
.listview__image {
    margin-right: 20px;
}
.listview__image img {
    width: 150px;
    height: 80px;
    object-fit: cover;
}
</style>